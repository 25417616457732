import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, Outlet } from "react-router-dom";
import "./App.css";
import {
  Description,
  Engineering,
  FormatListBulleted,
  Inventory,
  Logout,
} from "@mui/icons-material";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
const drawerWidth = 240;

export default function App() {
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        {/* <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
          }}
        >
        </AppBar> */}
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar>
            <List>
              <ListItem disablePadding>
                <ListItemIcon>
                  <img id="app-logo" src="waldo.png" alt="" height="30px" />
                </ListItemIcon>
                <ListItemText primary={"WALDO"} />
              </ListItem>
            </List>
          </Toolbar>
          <Divider />
          <List>
            <ListItem>
              <ListItemText primary={"drohne"} />
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant="caption">Prozesstest</Typography>
              </ListItemText>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="processlogs">
                <ListItemIcon>
                  <Description />
                </ListItemIcon>
                <ListItemText primary={"Prozesslogs"} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="processdeployments">
                <ListItemIcon>
                  <Engineering />
                </ListItemIcon>
                <ListItemText primary={"Prozess-Deployments"} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="formdeployments">
                <ListItemIcon>
                  <FormatListBulleted />
                </ListItemIcon>
                <ListItemText primary={"Formular-Deployments"} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="processes">
                <ListItemIcon>
                  <Inventory />
                </ListItemIcon>
                <ListItemText primary={"Prozesse (Beta)"} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            p: 3,
          }}
        >
          {/* <Toolbar /> */}
          <Outlet />
        </Box>
      </ThemeProvider>
    </Box>
  );
}
